import React from "react"
import * as R from "ramda"
import { graphql, Link, useStaticQuery } from "gatsby"
import { BgImage } from "gbimage-bridge"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import * as styles from "./history-hero.module.scss"

const HistoryHero = () => {
  const { vector } = useStaticQuery(
    graphql`
      {
        vector: file(relativePath: { eq: "white-house.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    `
  )

  const vectorImage = R.path(["childImageSharp", "gatsbyImageData"], vector)

  return (
    <Box>
      <Box style={{ position: "relative" }}>
        <Box style={{ backgroundColor: "#69d2fa", textAlign: "center" }}>
          <BgImage
            image={vectorImage}
            style={{
              minHeight: "190px",
              backgroundSize: "179px 152px",
              backgroundPosition: "370px 40px",
            }}
          >
            <h1 className={styles.heading}>Vuosien varrelta</h1>
          </BgImage>
        </Box>
      </Box>

      <Box style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Container
          style={{ maxWidth: "1240px", width: "100%", padding: "0px 30px" }}
        >
          <Grid container direction="row" spacing={3}>
            <Grid item sm={12} md={6} className={styles.paragraph__top}>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Ensimmäiset Asuntomessut näkivät päivänvalon Tuusulassa 1970.
                </span>{" "}
                Tällöin toiminta-ajatuksena oli lisätä asuntotietoutta sekä
                parantaa asuntosuunnittelun ja -rakentamisen laatua. Siitä
                lähtien messuja on järjestetty lähes vuosittain ja joinain
                vuosina jopa useammat, aina tähän päivään saakka.
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  1970 Tuusulan ensimmäiset Asuntomessut toivat oman tuvan, oman
                  luvan kaiken kansan saataville.
                </span>
                Vaikka lähiöasuminen oli suosittua, moni suomalainen halusi
                edelleen asua omassa talossa. Tuusulan Asuntomessut vastasi
                tähän unelmaan tarjoamalla edullisempia vaihtoehtoja
                omakotiasumiseen. Ajan hengen mukaisesti arkkitehtuuri oli
                modernia ja uutta luovaa. Arkkitehtuuria sekä ihasteltiin että
                kauhisteltiin.
              </p>
            </Grid>
            <Grid item sm={12} md={6} className={styles.paragraph__bottom}>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Nyt vuonna 2020 olemme monelta osin samojen teemojen äärellä:
                  Asuntomessut haluaa edelleen edistää hyvää asumista,
                  erityisesti sen kestävyyttä.
                </span>{" "}
                Näkökulmamme on asukkaan. Tavoitteemme on inspiroiden antaa
                oivalluksia fiksuihin asumisen ratkaisuihin. Asumisen
                ratkaisujen kirjo on vuosien varrella laajentunut. Enää ei
                puhuta pelkästä omakotiasumisesta tai arkkitehtuurista.{" "}
                <span style={{ fontWeight: "bold" }}>
                  Asuntomessuilta löytyy ideoita niin energiansäästöön,
                  sisustukseen, eri asumismuotoihin kuin kasvimaihin – eri
                  elämäntilanteisiin ja yksilöllisiin toiveisiin.
                </span>
              </p>
              <Link className="blue" to="#tulevaisuus">
                <Button variant="contained" color="primary">
                  Tulevaisuuteen
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default HistoryHero
