import React from "react"
import * as R from "ramda"
import { graphql, Link, useStaticQuery } from "gatsby"

import { BgImage } from "gbimage-bridge"
import { GatsbyImage } from "gatsby-plugin-image"

import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"

import VideoBox from "./video-box"

import { Link as ScrollLink } from "react-scroll"

import * as styles from "./history-line.module.scss"

/*
https://youtu.be/PcY0IkrNaWc - Elämä 1970s
https://youtu.be/5KktRMR0B_Y - Naapuruus 1990s
https://youtu.be/QBoAsKqZ0ls - Juurtuminen 2004
https://youtu.be/DrY8pAArygs - Yhteinen aika 2008
https://youtu.be/VrErqUVeGfg - Unelmat 2016
*/

const HistoryLine = () => {
  const {
    bgimage1970,
    bgimage1983,
    bgimage1993,
    bgimage2004,
    bgimage2010,
    tuusula1970,
    MynamakiJaVantaa1977,
    kuopio1980,
    helsinki1981,
    Jyvaskyla1985,
    Tampere1990,
    Varkaus1991,
    Joensuu1995,
    Tuusula2000,
    Espoo2006,
    Valkeakoski2009,
    Kuopio2010,
    Tampere2012,
    Hyvinkaa2013,
    VantaaInstagram2015,
    Vantaa2015,
    MikkeliInstagram2017,
    Tuusula2020,
    Lohja2021,
    Naantali2022,
    yellowTab,
    Asuntomessut_muistot_instagram11_1970,
    Asuntomessut_muistot_instagram13_1970,
    Asuntomessut_muistot_instagram_2000,
    Asuntomessut_muistot_instagram2_2000,
    Asuntomessut_muistot_instagram3_2002,
    Asuntomessut_muistot_instagram4_2002,
    Asuntomessut_muistot_instagram5_2004,
    Asuntomessut_muistot_instagram6_2004,
    Asuntomessut_muistot_instagram12_2015,
  } = useStaticQuery(
    graphql`
      {
        bgimage1970: file(relativePath: { eq: "Group 138.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 875
              height: 554
              layout: FIXED
            )
          }
        }
        bgimage1983: file(relativePath: { eq: "kaari 1.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 955
              height: 955
              layout: FIXED
            )
          }
        }
        bgimage1993: file(relativePath: { eq: "Group 132.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 815
              height: 966
              layout: FIXED
            )
          }
        }
        bgimage2004: file(relativePath: { eq: "talo.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 1000
              height: 1000
              layout: FIXED
            )
          }
        }
        bgimage2010: file(relativePath: { eq: "Group 114.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              width: 904
              height: 572
              layout: FIXED
            )
          }
        }
        tuusula1970: file(relativePath: { eq: "1970_tuusula.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram11_1970: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram11_1970.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram13_1970: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram13_1970.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        kerava1974: file(relativePath: { eq: "1974_kerava.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        MynamakiJaVantaa1977: file(
          relativePath: { eq: "1977_Mynamaki_ja_Vantaa.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        kuopio1980: file(relativePath: { eq: "1980_kuopio.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        helsinki1981: file(relativePath: { eq: "1981_helsinki.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Kuusankoski1983: file(relativePath: { eq: "1983_Kuusankoski.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Jyvaskyla1985: file(relativePath: { eq: "1985_Jyvaskyla.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Tampere1990: file(relativePath: { eq: "1990_Tampere.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Varkaus1991: file(relativePath: { eq: "1991_Varkaus.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Lahti1993: file(relativePath: { eq: "1993_Lahti.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Joensuu1995: file(relativePath: { eq: "1995_Joensuu.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram_2000: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram_2000.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram2_2000: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram2_2000.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Tuusula2000: file(relativePath: { eq: "2000_Tuusula.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram3_2002: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram3_2002.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram4_2002: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram4_2002.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram6_2004: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram6_2004.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram5_2004: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram5_2004.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Heinola2004: file(relativePath: { eq: "2004_Heinola.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Espoo2006: file(relativePath: { eq: "2006_Espoo.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Valkeakoski2009: file(relativePath: { eq: "2009_Valkeakoski.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Kuopio2010: file(relativePath: { eq: "2010_Kuopio.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Tampere2012: file(relativePath: { eq: "2012_Tampere.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Hyvinkaa2013: file(relativePath: { eq: "2013_Hyvinkaa.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Asuntomessut_muistot_instagram12_2015: file(
          relativePath: { eq: "Asuntomessut_muistot_instagram12_2015.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        VantaaInstagram2015: file(
          relativePath: { eq: "2015_Vantaa_instagram.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Vantaa2015: file(relativePath: { eq: "2015_Vantaa.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Seinajoki2016: file(relativePath: { eq: "2016_Seinajoki.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        MikkeliInstagram2017: file(
          relativePath: { eq: "2017_Mikkeli_instagram.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Tuusula2020: file(relativePath: { eq: "2020_Tuusula.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Lohja2021: file(relativePath: { eq: "2021_Lohja.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        Naantali2022: file(relativePath: { eq: "2022_Naantali.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        yellowTab: file(relativePath: { eq: "yellow_tab.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    `
  )

  const bgimage1970Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    bgimage1970
  )
  const bgimage1983Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    bgimage1983
  )
  const bgimage1993Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    bgimage1993
  )
  const bgimage2004Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    bgimage2004
  )
  const bgimage2010Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    bgimage2010
  )

  const tuusula1970Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    tuusula1970
  )
  const MynamakiJaVantaa1977Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    MynamakiJaVantaa1977
  )

  const kuopio1980Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    kuopio1980
  )
  const helsinki1981Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    helsinki1981
  )
  const Jyvaskyla1985Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Jyvaskyla1985
  )

  const Tampere1990Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Tampere1990
  )
  const Varkaus1991Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Varkaus1991
  )
  const Joensuu1995Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Joensuu1995
  )

  const Tuusula2000Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Tuusula2000
  )
  const Espoo2006Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Espoo2006
  )
  const Valkeakoski2009Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Valkeakoski2009
  )

  const Kuopio2010Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Kuopio2010
  )
  const Tampere2012Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Tampere2012
  )
  const Hyvinkaa2013Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Hyvinkaa2013
  )
  const VantaaInstagram2015Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    VantaaInstagram2015
  )
  const Vantaa2015Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Vantaa2015
  )
  const MikkeliInstagram2017Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    MikkeliInstagram2017
  )

  const Tuusula2020Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Tuusula2020
  )
  const Lohja2021Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Lohja2021
  )
  const Naantali2022Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Naantali2022
  )

  const Asuntomessut_muistot_instagram11_1970Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram11_1970
  )
  const Asuntomessut_muistot_instagram13_1970Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram13_1970
  )
  const Asuntomessut_muistot_instagram_2000Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram_2000
  )
  const Asuntomessut_muistot_instagram2_2000Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram2_2000
  )
  const Asuntomessut_muistot_instagram3_2002Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram3_2002
  )
  const Asuntomessut_muistot_instagram4_2002Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram4_2002
  )
  const Asuntomessut_muistot_instagram5_2004Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram5_2004
  )
  const Asuntomessut_muistot_instagram6_2004Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram6_2004
  )
  const Asuntomessut_muistot_instagram12_2015Image = R.path(
    ["childImageSharp", "gatsbyImageData"],
    Asuntomessut_muistot_instagram12_2015
  )

  const yellowTabImage = R.path(
    ["childImageSharp", "gatsbyImageData"],
    yellowTab
  )

  return (
    <Box>
      <Box className={styles.timeline__menu}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid>
            <ScrollLink to="2020" smooth={true} duration={500}>
              2020
            </ScrollLink>
          </Grid>
          <Grid>
            <ScrollLink to="2010" smooth={true} duration={500}>
              2010
            </ScrollLink>
          </Grid>
          <Grid>
            <ScrollLink to="2000" smooth={true} duration={500}>
              2000
            </ScrollLink>
          </Grid>
          <Grid>
            <ScrollLink to="1990" smooth={true} duration={500}>
              1990
            </ScrollLink>
          </Grid>
          <Grid>
            <ScrollLink to="1980" smooth={true} duration={500}>
              1980
            </ScrollLink>
          </Grid>
          <Grid>
            <ScrollLink to="1970" smooth={true} duration={500}>
              1970
            </ScrollLink>
          </Grid>
        </Grid>
      </Box>

      {/** 2020 starts here */}

      <Box
        id="tulevaisuus"
        className={styles.timeline__lightBlue}
        style={{ marginBottom: "30px" }}
        name="2020"
      >
        {/*
                <Box>
                    <Container className={ styles.timeline__header } style={{maxWidth: '1240px', width: '100%' }}>
                        <Grid container direction="column" spacing={6}>
                            <Grid item sm={12}>
                                <h2 className={styles.timeline__heading} >Mitä voisi olla?</h2>
                            </Grid>
                            <Grid item sm={12}>
                                <Box style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                    <p className={styles.timeline__heading__paragraph}>
                                        Missä ja miten asumme tulevaisuudessa? Millainen on
                                        kaunis ja kestävä koti? Millaisessa asuinympäristössä voimme hyvin? Asuntomessut 
                                        haluaa olla entistä vahvemmin ratkomassa näitä kysymyksiä yhdessä asukkaiden ja 
                                        asiantuntijoiden kanssa. Tähän tarvitaan herkkää korvaa, luovaa mieltä sekä 
                                        yhteistyötä meiltä kaikilta. Tervetuloa mukaan!
                                    </p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                */}

        <Box>
          <Container
            className={styles.timeline__header}
            style={{ maxWidth: "1240px", width: "100%" }}
          >
            <Grid container direction="row" spacing={6}>
              <Grid item sm={12} md={6}>
                <h2 className={styles.timeline__heading}>2020</h2>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p className={styles.timeline__heading__paragraph}>
                    Ikääntyneiden osuus väestöstä kasvaa voimakkaasti. Samaan
                    aikaan digitalisaatio murtaa totuttuja toimisen tapoja.
                    Millaisilla ratkaisuilla voisimme helpottaa arkea
                    kodeissamme? Miten uudet palvelumuodot voisivat muokata
                    kotejamme tulevaisuudessa?
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className={styles.timeline__container}>
          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__line}></Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          {/*
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="center">
                                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                                    <Box className={styles.timeline__entry} style={{ minHeight: '200px' }}>
                                        <p className={styles.timeline__year}>2025</p>
                                        <p className={styles.timeline__location}>Oulu</p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="center">
                                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                                    <Box className={styles.timeline__entry}>
                                        <p className={styles.timeline__year}>2024</p>
                                        <p className={styles.timeline__location}>Kerava</p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="center">
                                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                                    <Box className={styles.timeline__entry}>
                                        <p className={styles.timeline__year}>2023</p>
                                        <p className={styles.timeline__location}>Loviisa</p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
*/}
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="flex-start">
                                <Grid className={`${styles.timeline__item1} ${styles.timeline__offset} `} item sm={12} md={5}>
                                    <GatsbyImage
                                        alt=""
                                        image={Naantali2022Image}
                                        style={{ width: "100%" }}
                                      />
                                    <h3>Ilmastonmuutos</h3>
                                    <p>
                                        Ilmastonmuutoksen ratkaiseminen on yksi aikamme polttavimpia kysymyksiä. 
                                        Miten voisimme tehokkaimmin vaikuttaa rakentamisen ja asumisen
                                        ilmastovaikutuksiin? Millaisia vaikuttavampia tekoja voisimme arjessamme 
                                        tehdä ilmaston hyväksi?
                                    </p>
                                </Grid>
                                <Grid className={ styles.timeline__item2 } item sm={12} md={2} style={{ textAlign: "center" }}>
                                    <Box className={styles.timeline__entry}>
                                        <p className={styles.timeline__year}>2022</p>
                                        <p className={styles.timeline__location}>Naantali</p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
    
          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2021</p>
                    <p className={styles.timeline__location}>Lohja</p>
                  </Box>
                </Grid>
                <Grid className={styles.timeline__offset} item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={Lohja2021Image}
                    style={{ width: "100%" }}
                  />
                  <h3>Kaupungistuminen</h3>
                  <p>
                    Väestö keskittyy kasvukeskuksiin ja niiden laidoille, mikä
                    muuttaa yhteiskuntaamme merkittävästi. Miten voisimme tehdä
                    ekologisia ja ihmisen tarpeet huomioivia, monimuotoisia
                    asuinympäristöjä?
                  </p>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid className={styles.timeline__item1} item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={Tuusula2020Image}
                    style={{ width: "100%" }}
                  />
                  <h3>
                    Ikärakenteen muutos, uudenlaiset palvelut ja digitalisaatio
                  </h3>
                  <p>
                    Ikääntyneiden osuus väestöstä kasvaa voimakkaasti. Samaan
                    aikaan digitalisaatio murtaa totuttuja toimisen tapoja.
                    Millaisilla ratkaisuilla voisimme helpottaa arkea
                    kodeissamme? Miten uudet palvelumuodot voisivat muokata
                    kotejamme tulevaisuudessa?
                  </p>
                  <Link to={`/tuusula-2020/virtuaalimessut/`} className="blue">
                    <Button variant="contained" color="primary">
                      Katso virtuaalikohteet
                    </Button>
                  </Link>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2020</p>
                    <p className={styles.timeline__location}>Tuusula</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/*
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="flex-end">
                                <Grid className={ styles.timeline__voteBox } item sm={12} md={5}>
                                    <Backgroundimage className={ styles.timeline__voteBox__backgroundImage } gatsbyImageData={yellowTabImage}>
                                        <Box className={ styles.timeline__voteBox__container }>
                                            <h4 className={ styles.timeline__voteBox__heading }>Jaa oma muistosi</h4>
                                            <p>
                                                Millaisia muistoja sinulla on Asuntomessuilta? Jaa muistosi ja osallistu arvontaan! 
                                            </p>
                                            <Link className="yellow" to={'/osallistu/jaa-oma-messumuistosi'}  style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}>
                                                <Button variant="contained" color="primary">
                                                    Jaa MUISTOJA
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Backgroundimage> 
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    */}
        </Box>

        {/*
                <Box className={ styles.timeline__vote } style={{ textAlign: 'center', paddingTop: '70px', paddingBottom: '50px' }}>
                    <Container style={{maxWidth: '860px', width: '100%', padding: '0px 30px' }}>
                        <h4>
                            Mikä vuosi oli mielestäsi paras?
                        </h4>
                        <p style={{ maxWidth: '460px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            Oletko käynyt useamman kerran Asuntomessuilla? Kerro meille, mikä vuosi oli sinusta paras ja osallistu arvontaan.
                        </p>
                        <Link className="white" to={'/osallistu/historian-havinaa'}>
                            <Button variant="contained" color="primary">
                                OSALLISTU
                            </Button>
                        </Link>
                    </Container>
                </Box>
                */}
      </Box>

      {/** 2010 starts here */}

      <Box className={styles.timeline__pink} name="2010">
        <Box>
          <Container
            className={styles.timeline__header}
            style={{ maxWidth: "1240px", width: "100%" }}
          >
            <Grid container direction="row" spacing={6}>
              <Grid item sm={12} md={6}>
                <h2 className={styles.timeline__heading}>2010</h2>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p className={styles.timeline__heading__paragraph}>
                    Kiihtyvä kaupungistuminen alkoi tiivistää suuria ja
                    tyhjentää pieniä kaupunkeja. Ihmiset halusivat entistä
                    laajemmin vaikuttaa oman kodin lisäksi myös muuhun
                    asuinympäristöönsä. Ilmastoasiat nousivat myös
                    rakentamisessa keskiöön.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className={styles.timeline__container}>
          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__line}></Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box
                    className={styles.timeline__entry}
                    style={{ minHeight: "200px" }}
                  >
                    <p className={styles.timeline__year}>2019</p>
                    <p className={styles.timeline__location}>Kouvola</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2018</p>
                    <p className={styles.timeline__location}>Pori</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid className={styles.timeline__item1} item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={MikkeliInstagram2017Image}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2017</p>
                    <p className={styles.timeline__location}>Mikkeli</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2016</p>
                    <p className={styles.timeline__location}>Seinäjoki</p>
                  </Box>
                </Grid>
                <Grid item sm={12} md={5}>
                  {/* https://youtu.be/VrErqUVeGfg - Unelmat 2016 */}
                  <VideoBox url="https://youtu.be/VrErqUVeGfg" />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid
                  className={` ${styles.timeline__item1} ${styles.timeline__offset__small} ${styles.timeline__bottom__large} `}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={Vantaa2015Image}
                    style={{ width: "100%" }}
                  />
                  <h3>2015</h3>
                  <h3>
                    Vantaan Kivistössä rakennettiin hiiltä sitova puinen
                    kerrostalo{" "}
                  </h3>
                  <p>
                    Valmistuessaan puukerrostalo oli Euroopan suurin. Sen
                    rakentamiseen käytettiin kotimaista puuta.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2015</p>
                    <p className={styles.timeline__location}>Vantaa</p>
                  </Box>
                </Grid>
                <Grid
                  className={` ${styles.timeline__item3} ${styles.timeline__bottom__small} `}
                  item
                  sm={12}
                  md={5}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    swipeable
                    stopOnHover
                    showArrows
                    showStatus={false}
                    showIndicators={false}
                    interval={5000}
                  >
                    <GatsbyImage alt="" image={VantaaInstagram2015Image} />
                    <GatsbyImage
                      alt=""
                      image={Asuntomessut_muistot_instagram12_2015Image}
                    />
                  </Carousel>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box
                    className={`${styles.timeline__entry} ${styles.timeline__jyvaskyla}`}
                  >
                    <p className={styles.timeline__year}>2014</p>
                    <p className={styles.timeline__location}>Jyväskylä</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid
                  className={`${styles.timeline__hyvinkaa} ${styles.timeline__item1}`}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={Hyvinkaa2013Image}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2013</p>
                    <p className={styles.timeline__location}>Hyvinkää</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2012</p>
                    <p className={styles.timeline__location}>Tampere</p>
                  </Box>
                </Grid>

                <Grid item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={Tampere2012Image}
                    style={{ width: "100%" }}
                  />
                  <h3>2012</h3>
                  <h3>
                    Tampereella rakennettiin SOS-Lapsikylälle talo, jolla
                    luotiin turvalliset puitteet avun tarpeessa oleville
                    tamperelaisille lapsille ja nuorille{" "}
                  </h3>
                  <p>
                    Espoon Kauklahdessa järjestetyt Asuntomessut herätti myös
                    uinuvan junaseisakkeen eloon.
                  </p>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2011</p>
                    <p className={styles.timeline__location}>Kokkola</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid className={styles.timeline__item1} item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={Kuopio2010Image}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2010</p>
                    <p className={styles.timeline__location}>Kuopio</p>
                  </Box>
                </Grid>
                <Grid
                  className={styles.timeline__item3}
                  item
                  sm={12}
                  md={5}
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Hidden smDown>
                    <BgImage
                      image={bgimage2010Image}
                      style={{
                        zIndex: "-100",
                        backgroundPosition: "100% 100%",
                        width: "875px",
                        height: "554px",
                        marginRight: "-400px",
                        marginTop: "-150px",
                        backgroundSize: "contain",
                      }}
                    />
                  </Hidden>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/*
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="flex-end">
                                <Grid className={ styles.timeline__voteBox } item sm={12} md={5}>
                                    <Backgroundimage className={ styles.timeline__voteBox__backgroundImage } gatsbyImageData={yellowTabImage}>
                                        <Box className={ styles.timeline__voteBox__container }>
                                            <h4 className={ styles.timeline__voteBox__heading }>Jaa oma muistosi</h4>
                                            <p>
                                                Millaisia muistoja sinulla on Asuntomessuilta? Jaa muistosi ja osallistu arvontaan! 
                                            </p>
                                            <Link className="yellow" to={'/osallistu/jaa-oma-messumuistosi'}  style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}>
                                                <Button variant="contained" color="primary">
                                                    Jaa MUISTOJA
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Backgroundimage> 
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    */}
        </Box>

        {/*
                <Box className={ styles.timeline__vote } style={{ textAlign: 'center', paddingTop: '70px', paddingBottom: '50px' }}>
                    <Container style={{maxWidth: '860px', width: '100%', padding: '0px 30px' }}>
                        <h4>
                            Mikä vuosi oli mielestäsi paras?
                        </h4>
                        <p style={{ maxWidth: '460px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            Oletko käynyt useamman kerran Asuntomessuilla? Kerro meille, mikä vuosi oli sinusta paras ja osallistu arvontaan.
                        </p>
                        <Link className="white" to={'/osallistu/historian-havinaa'}>
                            <Button variant="contained" color="primary">
                                OSALLISTU
                            </Button>
                        </Link>
                    </Container>
                </Box>
                */}
      </Box>

      {/** 2000 starts here */}

      <Box className={styles.timeline__orange} name="2000">
        <Box>
          <Container
            className={styles.timeline__header}
            style={{ maxWidth: "1240px", width: "100%" }}
          >
            <Grid container direction="row" spacing={6}>
              <Grid item sm={12} md={6}>
                <h2 className={styles.timeline__heading}>2000</h2>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p className={styles.timeline__heading__paragraph}>
                    Nopeasti kehittyvä digitaalinen teknologia mahdollisti
                    uudentyyppisten yhteisöjen muodostumisen ja entistä
                    aktiivisemman elämän. Ympäristötietoisten teknologiat
                    kaupallistuivat, ja jättömaita ja teollisuusalueita alettiin
                    ottaa asumisen käyttöön.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className={styles.timeline__container}>
          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__line}></Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid className={styles.timeline__item1} item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={Valkeakoski2009Image}
                    style={{ width: "100%" }}
                  />
                  <h3>2009</h3>
                  <h3>
                    Valkeakoskella passiivirakentamisesta tehtiin koko kansan
                    asia
                  </h3>
                  <p>
                    Messuilla rakennettiin kokonaiset kolme taloa, joiden
                    lämmityksen energiankulutus oli nolla.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2009</p>
                    <p className={styles.timeline__location}>Valkeakoski</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2008</p>
                    <p className={styles.timeline__location}>Vaasa</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2007</p>
                    <p className={styles.timeline__location}>Hämeenlinna</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2006</p>
                    <p className={styles.timeline__location}>Espoo</p>
                  </Box>
                </Grid>
                <Grid item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={Espoo2006Image}
                    style={{ width: "100%" }}
                  />
                  <h3>2006</h3>
                  <h3>
                    Kauklahden asuntomessut näyttivät, miten kaupunki voisi
                    kasvaa kauniisti ja kestävästi{" "}
                  </h3>
                  <p>
                    Espoon Kauklahdessa järjestetyt Asuntomessut herätti myös
                    uinuvan junaseisakkeen eloon.
                  </p>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2005</p>
                    <p className={styles.timeline__location}>Oulu</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid
                  item
                  sm={12}
                  md={5}
                  className={` ${styles.timeline__item1} ${styles.flexEnd}`}
                >
                  <Hidden smDown>
                    <BgImage
                      image={bgimage2004Image}
                      style={{
                        marginLeft: "-400px",
                        paddingLeft: "400px",
                        zIndex: "-100",
                        paddingTop: "400px",
                        backgroundPosition: "0% 0%",
                        width: "1000px",
                        height: "1000px",
                        backgroundSize: "contain",
                      }}
                    >
                      <VideoBox url="https://youtu.be/QBoAsKqZ0ls" />
                    </BgImage>
                  </Hidden>
                  <Hidden mdUp>
                    <VideoBox url="https://youtu.be/QBoAsKqZ0ls" />
                  </Hidden>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2004</p>
                    <p className={styles.timeline__location}>Heinola</p>
                  </Box>
                </Grid>
                <Grid
                  className={styles.timeline__item3}
                  item
                  sm={12}
                  md={5}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    swipeable
                    stopOnHover
                    showArrows
                    showStatus={false}
                    showIndicators={false}
                    interval={5000}
                  >
                    <GatsbyImage
                      alt=""
                      image={Asuntomessut_muistot_instagram5_2004Image}
                    />
                    <GatsbyImage
                      alt=""
                      image={Asuntomessut_muistot_instagram6_2004Image}
                    />
                  </Carousel>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2003</p>
                    <p className={styles.timeline__location}>Laukaa</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid className={styles.timeline__item1} item sm={12} md={5}>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    swipeable
                    stopOnHover
                    showArrows
                    showStatus={false}
                    showIndicators={false}
                    interval={5000}
                  >
                    <GatsbyImage
                      alt=""
                      image={Asuntomessut_muistot_instagram3_2002Image}
                    />
                    <GatsbyImage
                      alt=""
                      image={Asuntomessut_muistot_instagram4_2002Image}
                    />
                  </Carousel>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2002</p>
                    <p className={styles.timeline__location}>Kotka</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2001</p>
                    <p className={styles.timeline__location}>Kajaani</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid
                  className={` ${styles.timeline__item1} ${styles.timeline__bottom__large} `}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={Tuusula2000Image}
                    style={{ width: "100%" }}
                  />
                  <h3>2000</h3>
                  <h3>Tuusulassa rakentamisessa suojeltiin pohjavesiä</h3>
                  <p>
                    Tuusulan messualue osoitti, että ekologisuus on
                    energiatehokkuutta laajempi käsite. Alue rakennettiin
                    vanhalle soranottoalueelle, jonka pohjavedet suojattiin
                    omalla eristekerroksella.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>2000</p>
                    <p className={styles.timeline__location}>Tuusula</p>
                  </Box>
                </Grid>
                <Grid className={styles.timeline__item3} item sm={12} md={5}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    <Carousel
                      showThumbs={false}
                      autoPlay
                      infiniteLoop
                      swipeable
                      stopOnHover
                      showArrows
                      showStatus={false}
                      showIndicators={false}
                      interval={5000}
                    >
                      <GatsbyImage
                        alt=""
                        image={Asuntomessut_muistot_instagram_2000Image}
                      />
                      <GatsbyImage
                        alt=""
                        image={Asuntomessut_muistot_instagram2_2000Image}
                      />
                    </Carousel>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/*
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="flex-end">
                                <Grid className={ styles.timeline__voteBox } item sm={12} md={5}>
                                    <Backgroundimage className={ styles.timeline__voteBox__backgroundImage } gatsbyImageData={yellowTabImage}>
                                        <Box className={ styles.timeline__voteBox__container }>
                                            <h4 className={ styles.timeline__voteBox__heading }>Jaa oma muistosi</h4>
                                            <p>
                                                Millaisia muistoja sinulla on Asuntomessuilta? Jaa muistosi ja osallistu arvontaan! 
                                            </p>
                                            <Link className="yellow" to={'/osallistu/jaa-oma-messumuistosi'}  style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}>
                                                <Button variant="contained" color="primary">
                                                    Jaa MUISTOJA
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Backgroundimage> 
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    */}
        </Box>

        {/*
                <Box className={ styles.timeline__vote } style={{ textAlign: 'center', paddingTop: '70px', paddingBottom: '50px' }}>
                    <Container style={{maxWidth: '860px', width: '100%', padding: '0px 30px' }}>
                        <h4>
                            Mikä vuosi oli mielestäsi paras?
                        </h4>
                        <p style={{ maxWidth: '460px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            Oletko käynyt useamman kerran Asuntomessuilla? Kerro meille, mikä vuosi oli sinusta paras ja osallistu arvontaan.
                        </p>
                        <Link className="white" to={'/osallistu/historian-havinaa'}>
                            <Button variant="contained" color="primary">
                                OSALLISTU
                            </Button>
                        </Link>
                    </Container>
                </Box>
                */}
      </Box>

      {/** 1990 starts here */}

      <Box className={styles.timeline__green} name="1990">
        <Box>
          <Container
            className={styles.timeline__header}
            style={{ maxWidth: "1240px", width: "100%" }}
          >
            <Grid container direction="row" spacing={6}>
              <Grid item sm={12} md={6}>
                <h2 className={styles.timeline__heading}>1990</h2>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p className={styles.timeline__heading__paragraph}>
                    Käsitys hyvästä asumisesta laajeni. Perhe, ympäristö ja
                    erityisryhmien tarpeet muuttuivat asumisessa yhä
                    tärkeämmiksi. Tietoyhteiskunnan ensiaskeleet sekä 90-luvun
                    alun lama vaikuttivat asumiseen voimakkaasti.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className={styles.timeline__container}>
          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__line}></Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box
                    className={styles.timeline__entry}
                    style={{ minHeight: "200px" }}
                  >
                    <p className={styles.timeline__year}>1999</p>
                    <p className={styles.timeline__location}>Lappeenranta</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1998</p>
                    <p className={styles.timeline__location}>Rovaniemen mlk</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1997</p>
                    <p className={styles.timeline__location}>Raisio</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1996</p>
                    <p className={styles.timeline__location}>Ylöjärvi</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid
                  className={` ${styles.timeline__item1} ${styles.timeline__offset__small} `}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={Joensuu1995Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1995</h3>
                  <h3>Joensuu näytti, mitä esteettömyys voisi olla</h3>
                  <p>
                    Liikkumisen erityistarpeiset kohderyhmät olivat koko
                    Joensuun alueen suunnittelun lähtökohta. Se loi pohjaa
                    kokonaisvaltaiselle esteettömyydelle, joka ei lopu omalle
                    kotikynnykselle.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1995</p>
                    <p className={styles.timeline__location}>Joensuu</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1994</p>
                    <p className={styles.timeline__location}>Pietarsaari</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1993</p>
                    <p className={styles.timeline__location}>Lahti</p>
                  </Box>
                </Grid>

                <Grid item sm={12} md={5} className={`${styles.flexEnd}`}>
                  <Hidden smDown>
                    <BgImage
                      image={bgimage1993Image}
                      style={{
                        marginRight: "-400px",
                        paddingRight: "400px",
                        paddingTop: "200px",
                        zIndex: "-100",
                        backgroundPosition: "0% 0%",
                        width: "815px",
                        height: "966px",
                        backgroundSize: "contain",
                      }}
                    >
                      <VideoBox url="https://youtu.be/5KktRMR0B_Y" />
                    </BgImage>
                  </Hidden>
                  <Hidden mdUp>
                    <VideoBox url="https://youtu.be/5KktRMR0B_Y" />
                  </Hidden>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1992</p>
                    <p className={styles.timeline__location}>Mäntsälä</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid
                  className={` ${styles.timeline__item1} ${styles.timeline__offset__large} `}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={Varkaus1991Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1991</h3>
                  <h3>Varkaudessa esiteltiin Suomen ensimmäinen älytalo</h3>
                  <p>
                    Silloisen teknologian eturintamassa olleessa talossa oli
                    tietoverkko ja talotekniikalla lisättiin asumismukavuutta ja
                    turvallisuutta. Se oli siihen aikaan uutisen arvoinen asia.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1991</p>
                    <p className={styles.timeline__location}>Varkaus</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1990</p>
                    <p className={styles.timeline__location}>Tampere</p>
                  </Box>
                </Grid>
                <Grid item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={Tampere1990Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1990</h3>
                  <h3>
                    Tampereen messuilla muutettiin kehräämön osia kodeiksi
                  </h3>
                  <p>
                    Tampereen Lapinnimen kehräämö muutettiin asunnoiksi osana
                    Asuntomessuja. Kehräämö oli ensimmäisiä Suomessa tehtyjä
                    laajamittaisia rakennuksen käyttötavan muutoshankkeita.
                  </p>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/*
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="flex-end">
                                <Grid className={ styles.timeline__voteBox } item sm={12} md={5}>
                                    <Backgroundimage className={ styles.timeline__voteBox__backgroundImage } gatsbyImageData={yellowTabImage}>
                                        <Box className={ styles.timeline__voteBox__container }>
                                            <h4 className={ styles.timeline__voteBox__heading }>Jaa oma muistosi</h4>
                                            <p>
                                                Millaisia muistoja sinulla on Asuntomessuilta? Jaa muistosi ja osallistu arvontaan! 
                                            </p>
                                            <Link className="yellow" to={'/osallistu/jaa-oma-messumuistosi'}  style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}>
                                                <Button variant="contained" color="primary">
                                                    Jaa MUISTOJA
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Backgroundimage> 
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    */}
        </Box>

        {/*
                <Box className={ styles.timeline__vote } style={{ textAlign: 'center', paddingTop: '70px', paddingBottom: '50px' }}>
                    <Container style={{maxWidth: '860px', width: '100%', padding: '0px 30px' }}>
                        <h4>
                            Mikä vuosi oli mielestäsi paras?
                        </h4>
                        <p style={{ maxWidth: '460px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            Oletko käynyt useamman kerran Asuntomessuilla? Kerro meille, mikä vuosi oli sinusta paras ja osallistu arvontaan.
                        </p>
                        <Link className="white" tto={'/osallistu/historian-havinaa'}>
                            <Button variant="contained" color="primary">
                                OSALLISTU
                            </Button>
                        </Link>
                    </Container>
                </Box>
                */}
      </Box>

      {/** 1980 starts here */}

      <Box className={styles.timeline__blue} name="1980">
        <Box>
          <Container
            className={styles.timeline__header}
            style={{ maxWidth: "1240px", width: "100%" }}
          >
            <Grid container direction="row" spacing={6}>
              <Grid item sm={12} md={6}>
                <h2 className={styles.timeline__heading}>1980</h2>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p className={styles.timeline__heading__paragraph}>
                    Elintaso nousi, maailma aukeni ja ihmiset alkoivat haluta
                    asumiselta myös elämyksiä ja itsensä toteuttamista. Ymmärrys
                    oman vanhan rakennusperinteen merkityksestä nousi tehokkaan
                    rakentamisen rinnalle.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className={styles.timeline__container}>
          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__line}></Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box
                    className={styles.timeline__entry__disable}
                    style={{ minHeight: "150px" }}
                  >
                    <p className={styles.timeline__year}>1989</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1988</p>
                    <p className={styles.timeline__location}>Turku</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1987</p>
                    <p className={styles.timeline__location}>Tornio</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1986</p>
                    <p className={styles.timeline__location}>Imatra</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid
                  className={` ${styles.timeline__item1} ${styles.timeline__offset__large} `}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={Jyvaskyla1985Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1985</h3>
                  <h3>
                    Jyväskylässä luotiin malleja toimivalle yhteisöasumiselle
                  </h3>
                  <p>
                    Jyväskylän asuntomessujen yhteisasumisratkaisut, pihakadut
                    ja yhteiskäyttötilat ennakoivat jo tätä päivää ja nyt
                    suosittua yhteisöllistä asumista.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1985</p>
                    <p className={styles.timeline__location}>Jyväskylä</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__disable}>
                    <p className={styles.timeline__year}>1984</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1983</p>
                    <p className={styles.timeline__location}>Kuusankoski</p>
                  </Box>
                </Grid>

                <Grid item sm={12} md={5} className={`${styles.flexStart}`}>
                  <Hidden smDown>
                    <BgImage
                      image={bgimage1983Image}
                      style={{
                        marginRight: "-300px",
                        paddingRight: "300px",
                        paddingTop: "200px",
                        zIndex: "-100",
                        backgroundPosition: "0% 0%",
                        width: "955px",
                        height: "755px",
                        backgroundSize: "contain",
                      }}
                    >
                      <VideoBox url="https://youtu.be/DrY8pAArygs" />
                    </BgImage>
                  </Hidden>
                  <Hidden mdUp>
                    <VideoBox url="https://youtu.be/DrY8pAArygs" />
                  </Hidden>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1982</p>
                    <p className={styles.timeline__location}>Forssa</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid
                  className={`${styles.timeline__item1} ${styles.timeline__offset}`}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={helsinki1981Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1981</h3>
                  <h3>Asuntomessut pelasti Puu-Vallilan</h3>
                  <p>
                    1970-luvulla Helsinki oli menettänyt jo Puu-Pasilan ja
                    seuraavana purku-uhan alla oli 1910-luvulla rakennettu
                    Puu-Vallila. Vuoden 1981 Asuntomessuille saneeratut Vallilan
                    puutalot osoittivat, että vanhakin voi olla arvokasta.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1981</p>
                    <p className={styles.timeline__location}>Helsinki</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1980</p>
                    <p className={styles.timeline__location}>Kuopio</p>
                  </Box>
                </Grid>
                <Grid
                  className={styles.timeline__offset__large}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={kuopio1980Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1980</h3>
                  <h3>Kuopiossa otettiin auringosta energiaa</h3>
                  <p>
                    Kuopiossa 1980 esiteltiin paljon erilaisia
                    aurinkokeräinratkaisuja. Messuilla osoitettiin, että
                    energiatehokkuus ei tarkoita tylsää arkkitehtuuria tai
                    asumismukavuudesta tinkimistä.
                  </p>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/*
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="flex-end">
                                <Grid className={ styles.timeline__voteBox } item sm={12} md={5}>
                                    <Backgroundimage className={ styles.timeline__voteBox__backgroundImage } gatsbyImageData={yellowTabImage}>
                                        <Box className={ styles.timeline__voteBox__container }>
                                            <h4 className={ styles.timeline__voteBox__heading }>Jaa oma muistosi</h4>
                                            <p>
                                                Millaisia muistoja sinulla on Asuntomessuilta? Jaa muistosi ja osallistu arvontaan!
                                            </p>
                                            <Link className="yellow" to={'/osallistu/jaa-oma-messumuistosi'}  style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}>
                                                <Button variant="contained" color="primary">
                                                    Jaa MUISTOJA
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Backgroundimage> 
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    */}
        </Box>

        {/*
                <Box className={ styles.timeline__vote } style={{ textAlign: 'center', paddingTop: '70px', paddingBottom: '50px' }}>
                    <Container style={{maxWidth: '860px', width: '100%', padding: '0px 30px' }}>
                        <h4>
                            Mikä vuosi oli mielestäsi paras?
                        </h4>
                        <p style={{ maxWidth: '460px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            Oletko käynyt useamman kerran Asuntomessuilla? Kerro meille, mikä vuosi oli sinusta paras ja osallistu arvontaan.
                        </p>
                        <Link className="white" to={'/osallistu/historian-havinaa'}>
                            <Button variant="contained" color="primary">
                                OSALLISTU
                            </Button>
                        </Link>
                    </Container>
                </Box>
                */}
      </Box>

      {/** 1970 starts here */}

      <Box className={styles.timeline__yellow} name="1970">
        <Box>
          <Container
            className={styles.timeline__header}
            style={{ maxWidth: "1240px", width: "100%" }}
          >
            <Grid container direction="row" spacing={6}>
              <Grid item sm={12} md={6}>
                <h2 className={styles.timeline__heading}>1970</h2>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p className={styles.timeline__heading__paragraph}>
                    Suuren rakennemuutoksen aika toi suomalaiset maalta
                    kaupunkeihin ja lähiöihin. Ajan hengen mukaisesti kotien
                    rakentaminen nähtiin teollisena massatuotantona ja
                    suurprojektina, jonka tavoitteena oli tarjota ihmisille
                    nykyaikaisia asuntoja. Myös ensimmäiset ympäristöaatteet
                    alkoivat vaikuttaa rakentamiseen.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className={styles.timeline__container}>
          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1979</p>
                    <p className={styles.timeline__location}>Joutseno</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1978</p>
                    <p className={styles.timeline__location}>Lahti</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-start"
              >
                <Grid className={styles.timeline__item1} item sm={12} md={5}>
                  <GatsbyImage
                    alt=""
                    image={MynamakiJaVantaa1977Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1977</h3>
                  <h3>Vantaalle rakennettiin aikaansa edellä oleva ekotalo</h3>
                  <p>
                    Ekotalon energiankulutuksesta miltei 20% tuotettiin
                    aurinkoenergialla. Asukkaita ihastutti talon viherhuone.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry__medium__text}>
                    <p className={styles.timeline__year}>1977</p>
                    <p className={styles.timeline__location}>
                      Mynämäki ja Vantaa
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
          <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1976</p>
                    <p
                      className={styles.timeline__location}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Oulu
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box>

            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__long__text}>
                    <p className={styles.timeline__year}>1975</p>
                    <p className={styles.timeline__location}>
                      Hämeenlinna, Kokkola, Pori ja Vaasa
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  sm={12}
                  md={5}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Hidden smDown>
                    <BgImage
                      image={bgimage1970Image}
                      style={{
                        zIndex: "-100",
                        backgroundPosition: "100% 100%",
                        width: "875px",
                        height: "554px",
                        marginLeft: "-400px",
                        marginTop: "-200px",
                        backgroundSize: "contain",
                      }}
                    />
                  </Hidden>
                </Grid>
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1974</p>
                    <p className={styles.timeline__location}>Kerava</p>
                  </Box>
                </Grid>
                <Grid item sm={12} md={5}>
                  {/* https://youtu.be/PcY0IkrNaWc - Elämä 1970s */}
                  <VideoBox url="https://youtu.be/PcY0IkrNaWc" />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__disable}>
                    <p className={styles.timeline__year}>1973</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1972</p>
                    <p
                      className={styles.timeline__location}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Kangasala
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item sm={12} md={2} style={{ textAlign: "center" }}>
                  <Box className={styles.timeline__entry__disable}>
                    <p className={styles.timeline__year}>1971</p>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box>
            <Container style={{ maxWidth: "1240px", width: "100%" }}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid
                  className={` ${styles.timeline__item1} ${styles.timeline__bottom} `}
                  item
                  sm={12}
                  md={5}
                >
                  <GatsbyImage
                    alt=""
                    image={tuusula1970Image}
                    style={{ width: "100%" }}
                  />
                  <h3>1970</h3>
                  <h3>
                    Tuusulan ensimmäiset Asuntomessut toivat oman tuvan, oman
                    luvan kaiken kansan saataville
                  </h3>
                  <p>
                    Vaikka lähiöasuminen oli suosittua, moni suomalainen halusi
                    edelleen asua omassa talossa. Tuusulan messut vastasi tähän
                    unelmaan tarjoamalla edullisempia vaihtoehtoja
                    omakotiasumiseen. Ajan hengen mukaisesti arkkitehtuuri oli
                    modernia ja uutta luovaa. Arkkitehtuuria sekä ihasteltiin
                    että kauhisteltiin.
                  </p>
                </Grid>
                <Grid
                  className={styles.timeline__item2}
                  item
                  sm={12}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <Box className={styles.timeline__entry}>
                    <p className={styles.timeline__year}>1970</p>
                    <p className={styles.timeline__location}>Tuusula</p>
                  </Box>
                </Grid>
                <Grid className={styles.timeline__item3} item sm={12} md={5}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    <Carousel
                      showThumbs={false}
                      autoPlay
                      infiniteLoop
                      swipeable
                      stopOnHover
                      showArrows
                      showStatus={false}
                      showIndicators={false}
                      interval={5000}
                    >
                      <GatsbyImage
                        alt=""
                        image={Asuntomessut_muistot_instagram11_1970Image}
                      />
                      <GatsbyImage
                        alt=""
                        image={Asuntomessut_muistot_instagram13_1970Image}
                      />
                    </Carousel>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/*
                    <Box>
                        <Container
                        style={{ maxWidth: "1240px", width: "100%" }}
                        >
                            <Grid container direction="row" spacing={3} justify="flex-end">
                                <Grid className={ styles.timeline__voteBox } item sm={12} md={5}>
                                    <Backgroundimage className={ styles.timeline__voteBox__backgroundImage } gatsbyImageData={yellowTabImage}>
                                        <Box className={ styles.timeline__voteBox__container }>
                                            <h4 className={ styles.timeline__voteBox__heading }>Jaa oma muistosi</h4>
                                            <p>
                                                Millaisia muistoja sinulla on Asuntomessuilta? Jaa muistosi ja osallistu arvontaan!
                                            </p>
                                            <Link className="yellow" to={'/osallistu/jaa-oma-messumuistosi'} style={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}>
                                                <Button variant="contained" color="primary">
                                                    Jaa MUISTOJA
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Backgroundimage> 
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    */}
        </Box>

        {/*
                <Box className={ styles.timeline__vote } style={{ textAlign: 'center', paddingTop: '70px', paddingBottom: '50px' }}>
                    <Container style={{maxWidth: '860px', width: '100%', padding: '0px 30px' }}>
                        <h4>
                            Mikä vuosi oli mielestäsi paras?
                        </h4>
                        <p style={{ maxWidth: '460px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            Oletko käynyt useamman kerran Asuntomessuilla? Kerro meille, mikä vuosi oli sinusta paras ja osallistu arvontaan.
                        </p>
                        <Link className="white" to={'/osallistu/historian-havinaa'}>
                            <Button variant="contained" color="primary">
                                OSALLISTU
                            </Button>
                        </Link>
                    </Container>
                </Box>
                */}
      </Box>
    </Box>
  )
}

export default HistoryLine
