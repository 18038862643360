import React from "react"
import * as R from "ramda"
import { graphql, useStaticQuery } from "gatsby"

import { BgImage } from "gbimage-bridge"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"

import * as styles from "./history-alert.module.scss"

const HistoryAlert = () => {
  const { vector } = useStaticQuery(
    graphql`
      {
        vector: file(relativePath: { eq: "shape_green_pink.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    `
  )

  const vectorImage = R.path(["childImageSharp", "gatsbyImageData"], vector)

  return (
    <Box
      style={{
        overflow: "visable",
        backgroundColor: "#FFEBA8",
        textAlign: "center",
      }}
    >
      <BgImage
        className={styles.container}
        image={vectorImage}
        style={{ backgroundSize: "1198px 402px", backgroundPositionX: "370px" }}
      >
        <Container
          className={styles.containerPosition}
          style={{
            maxWidth: "860px",
            width: "100%",
            padding: "0px 30px",
            paddingTop: "-50px",
            paddingBottom: "-40px",
          }}
        >
          <h2 className={styles.heading}>
            Tutki aikajanalta lisää, mitä muuta vuosien varrella on tapahtunut -
            ja mitä tulevaisuudessa voisi olla.
          </h2>
        </Container>
      </BgImage>
    </Box>
  )
}

export default HistoryAlert
