import PropTypes from "prop-types"
import React from "react"

import ReactPlayer from "react-player"

import Box from "@material-ui/core/Box"

import * as styles from "./video-box.module.scss"

/*
https://youtu.be/QBoAsKqZ0ls - Juurtuminen
https://youtu.be/PcY0IkrNaWc - Elämä
https://youtu.be/DrY8pAArygs - Yhteinen aika
https://youtu.be/5KktRMR0B_Y - Naapuruus
https://youtu.be/VrErqUVeGfg - Unelmat
*/

const VideoBox = ({ url }) => {
  return (
    <Box id={`${url}`} className={styles.video}>
      <ReactPlayer width="100%" url={url} /> {/*//playing={isSelected} />*/}
    </Box>
  )
}

VideoBox.propTypes = {
  url: PropTypes.string.isRequired,
}

export default VideoBox
