import React from "react"

import Seo from "../components/seo"
import HistoryAlert from "../components/history-alert"
import HistoryHero from "../components/history-hero"
import HistoryLine from "../components/history-line"
import LayoutController from "../components/layoutController"

const HistoryPage = () => {

  return (
    <LayoutController language="fi"> 
      <Seo
        seoTitle="Vuosien varrelta"
        seoDesc="Tutustu Asuntomessujen historiaan"
        url={typeof window !== 'undefined' ? window.location.href : ''}
      />
      <HistoryHero />
      <HistoryAlert />
      <HistoryLine />

    </LayoutController>
  )
}

export default HistoryPage