// extracted by mini-css-extract-plugin
export var flexEnd = "history-line-module--flexEnd--CVinA";
export var flexStart = "history-line-module--flexStart--EJtrd";
export var timeline__blue = "history-line-module--timeline__blue--6O5Q3";
export var timeline__bottom = "history-line-module--timeline__bottom--rr3cv";
export var timeline__bottom__large = "history-line-module--timeline__bottom__large--yIeJQ";
export var timeline__bottom__small = "history-line-module--timeline__bottom__small--k6psS";
export var timeline__bottom__xlarge = "history-line-module--timeline__bottom__xlarge--xcKwH";
export var timeline__container = "history-line-module--timeline__container--1RYC8";
export var timeline__entry = "history-line-module--timeline__entry--XTDKJ";
export var timeline__entry__disable = "history-line-module--timeline__entry__disable--1Nuo2";
export var timeline__entry__line = "history-line-module--timeline__entry__line--5VNUX";
export var timeline__entry__long__text = "history-line-module--timeline__entry__long__text--N8Vrj";
export var timeline__entry__medium__text = "history-line-module--timeline__entry__medium__text--6ZMqM";
export var timeline__green = "history-line-module--timeline__green--wL9ag";
export var timeline__header = "history-line-module--timeline__header--4Maut";
export var timeline__heading = "history-line-module--timeline__heading--tVTz1";
export var timeline__heading__paragraph = "history-line-module--timeline__heading__paragraph--Y-PUp";
export var timeline__hyvinkaa = "history-line-module--timeline__hyvinkaa--llZe9";
export var timeline__item1 = "history-line-module--timeline__item1--GRsa6";
export var timeline__item2 = "history-line-module--timeline__item2--XMODW";
export var timeline__item3 = "history-line-module--timeline__item3--PzHe+";
export var timeline__jyvaskyla = "history-line-module--timeline__jyvaskyla--KEBq9";
export var timeline__lightBlue = "history-line-module--timeline__lightBlue--mmOdu";
export var timeline__location = "history-line-module--timeline__location--2-B44";
export var timeline__menu = "history-line-module--timeline__menu--jzpDA";
export var timeline__offset = "history-line-module--timeline__offset--PjSbV";
export var timeline__offset__large = "history-line-module--timeline__offset__large--T8zFZ";
export var timeline__offset__small = "history-line-module--timeline__offset__small--bSd8L";
export var timeline__orange = "history-line-module--timeline__orange--ZQXEV";
export var timeline__pink = "history-line-module--timeline__pink--5XAox";
export var timeline__vote = "history-line-module--timeline__vote--kjCsF";
export var timeline__voteBox = "history-line-module--timeline__voteBox--4JcP+";
export var timeline__voteBox__backgroundImage = "history-line-module--timeline__voteBox__backgroundImage--JpufH";
export var timeline__voteBox__container = "history-line-module--timeline__voteBox__container--lFx+m";
export var timeline__voteBox__heading = "history-line-module--timeline__voteBox__heading--MfmNT";
export var timeline__year = "history-line-module--timeline__year--pyX6d";
export var timeline__yellow = "history-line-module--timeline__yellow--8T1K7";